import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const DownloadComponent = ({ the_id, percentValue }) => {
    console.log("id:", the_id)

    let downloadableExcelLink = ""

    if (the_id === 0) {
        downloadableExcelLink = "https://niroggyan.s3.ap-south-1.amazonaws.com/other_health_dashboards_files/gujrat-data-unihealth-files/abnormal_profiles_excels/new-blood_counts.xlsx"
    }

    else if (the_id === 1) {
        downloadableExcelLink = "https://niroggyan.s3.ap-south-1.amazonaws.com/other_health_dashboards_files/gujrat-data-unihealth-files/abnormal_profiles_excels/diabetes_monitoring.xlsx"
    }

    else if (the_id === 2) {
        downloadableExcelLink = "https://niroggyan.s3.ap-south-1.amazonaws.com/other_health_dashboards_files/gujrat-data-unihealth-files/abnormal_profiles_excels/kidney_profile.xlsx"
    }

    else if (the_id === 3) {
        downloadableExcelLink = "https://niroggyan.s3.ap-south-1.amazonaws.com/other_health_dashboards_files/gujrat-data-unihealth-files/abnormal_profiles_excels/lipid_profile.xlsx"
    }

    else if (the_id === 4) {
        downloadableExcelLink = "https://niroggyan.s3.ap-south-1.amazonaws.com/other_health_dashboards_files/gujrat-data-unihealth-files/abnormal_profiles_excels/liver_profile.xlsx"
    }

    else if (the_id === 5) {
        downloadableExcelLink = "https://niroggyan.s3.ap-south-1.amazonaws.com/other_health_dashboards_files/gujrat-data-unihealth-files/abnormal_profiles_excels/urinalysis_profile.xlsx"
    }

    else if (the_id === 6) {
        downloadableExcelLink = "https://niroggyan.s3.ap-south-1.amazonaws.com/other_health_dashboards_files/gujrat-data-unihealth-files/abnormal_profiles_excels/anemia_profile.xlsx"
    }
    else if (the_id === 7) {
        downloadableExcelLink = "https://niroggyan.s3.ap-south-1.amazonaws.com/other_health_dashboards_files/gujrat-data-unihealth-files/abnormal_profiles_excels/blood_clotting_profile.xlsx"
    }
    else if (the_id === 8) {
        downloadableExcelLink = "https://niroggyan.s3.ap-south-1.amazonaws.com/other_health_dashboards_files/gujrat-data-unihealth-files/abnormal_profiles_excels/vitamin_profile.xlsx"
    }
    else if (the_id === 9) {
        downloadableExcelLink = ""
    }
    else if (the_id === 10) {
        downloadableExcelLink = "https://niroggyan.s3.ap-south-1.amazonaws.com/other_health_dashboards_files/gujrat-data-unihealth-files/abnormal_profiles_excels/thyroid_profile.xlsx"
    }

    return (
        <div>

            {downloadableExcelLink === "" ?
                <i
                    title="no abnormal tests"
                    className="fas far fa-file-excel download-file-disable-css">
                </i>
                :
                <a
                    href={downloadableExcelLink}
                    target='_blank'
                    download
                >
                    <i
                        title="get abnormal tests data"
                        className="fas far fa-file-excel download-file-css">
                    </i>
                </a>
            }
        </div>
    )
}

export default DownloadComponent