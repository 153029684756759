import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import Modal from 'react-modal';
import ProgressBar from "@ramonak/react-progress-bar";
import { JSON_LINK, green_max, orange_max, convert_test_index, loc_to_idx, dept_to_idx, total_bio_markers, convert_index_test } from "../utils";
import bloodCount from "../images/bloodCount.svg";
import kidney from "../images/kidney.svg";
import lipids from "../images/lipids.svg";
import thyroid from "../images/thyroid.svg";
import diabetes from "../images/diabetes.svg";
import liver from "../images/liver.svg";
import electrolytes from "../images/electrolytes.svg";
import urine from "../images/urine.svg";
import anemia from "../images/anemia.svg";
import vitaminB12 from "../images/vitaminB12.svg";
import vitaminD from "../images/vitaminD.svg";

import CBC from "../images/modal/CBC.svg";
import Kidney from "../images/modal/Kidneys.svg";
import Lipids from "../images/modal/Lipids.svg";
import Thyroid from "../images/modal/Thyroid.svg";
import Diabetes from "../images/modal/Diabetes.svg";
import Liver from "../images/modal/Liver.svg";
import Electrolytes from "../images/modal/Electrolytes.svg";
import Urine from "../images/modal/Urine.svg";
import Anemia from "../images/modal/Anemia.svg";
import VitaminB12 from "../images/modal/VitaminB12.svg";
import VitaminD from "../images/modal/VitaminD.svg";
import bmi_image from "../images/bmi_image.png"
import anemia_image from "../images/anemia.svg"
import blood_clotting from "../images/Blood-Clotting-icon.png"
import vitamin_image from '../images/Vitamin-Profile-icon.png'
import cancer_image from '../images/Cancer-Profile-icon.png'
import thyroid_image from '../images/Thyroid-Profile-icon.png'

import body_screening from "../images/body_screening.png"
import lifestyle_image from "../images/lifestyle.svg"
import body_system from "../images/Body_Systems.svg"
import covid_image from "../images/covid.svg"

import "../css/tests.css";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import { AiFillInfoCircle } from "react-icons/ai";
import { getByTitle } from "@testing-library/dom";
import { COLORS } from "../color";
import DownloadComponent from "./DownloadComponent";

var currjsondata;
var card_display = 0;
var testing = 0;
let download_location = 0


class Tests extends Component {
  state = {
    jsondata: [],
    openModal: false,
    nodeInnerText: "",
    currjsondata: [],
    data: [],
  };
  componentDidMount() {
    axios
      .get(
        JSON_LINK
      )
      .then((res) => {
        console.log("currjsondata\n", res)
        this.setState({
          pUp: false,
          pDown: false,
          tUp: false,
          tDown: false,
          rUp: false,
          rDown: false,
          data: res.data,
        });

        currjsondata = res.data;
      });

    console.log("Mount");
  }


  BoolToInt = (bool) => {
    if (bool === true) return 1;
    else return 0;
  };

  cardClicked = (e) => {
    var node = e.target.id;
    console.log(node)
    if (!(node in convert_test_index)) return;

    this.setState({
      openModal: true,
      nodeInnerText: node,
      nodeIndex: convert_test_index[node],
    });

  };

  closeModal = () => {
    this.setState({
      openModal: false,
    });
  };

  buttonSortDown = (e) => {

    if (e.target.id == "profile_down") {
      this.setState({
        pUp: false,
        pDown: true,
        tUp: false,
        tDown: false,
        rUp: false,
        rDown: false,
      });
    }
    else if (e.target.id == "tests_down") {
      this.setState({
        pUp: false,
        pDown: false,
        tUp: false,
        tDown: true,
        rUp: false,
        rDown: false,
      });
    }
    else if (e.target.id == "percentage_down") {
      this.setState({
        pUp: false,
        pDown: false,
        tUp: false,
        tDown: false,
        rUp: false,
        rDown: true,
      });
    }

  };

  buttonSortUp = (e) => {

    if (e.target.id == "profile_up") {
      this.setState({
        pUp: true,
        pDown: false,
        tUp: false,
        tDown: false,
        rUp: false,
        rDown: false,
      });
    }
    else if (e.target.id == "tests_up") {
      this.setState({
        pUp: false,
        pDown: false,
        tUp: true,
        tDown: false,
        rUp: false,
        rDown: false,
      });
    }
    else if (e.target.id == "percentage_up") {
      this.setState({
        pUp: false,
        pDown: false,
        tUp: false,
        tDown: false,
        rUp: true,
        rDown: false,
      });
    }
  }


  render() {

    var color;
    const denominator =
      (this.BoolToInt(this.props.young_male) +
        this.BoolToInt(this.props.young_female) +
        this.BoolToInt(this.props.old_male) +
        this.BoolToInt(this.props.old_female))

    const {
      data,
      pUp,
      pDown,
      tUp,
      tDown,
      rUp,
      rDown,
    } = this.state;


    // no_test and professionals
    var professionals = 0;
    var no_tests = 0;
    var divisor = 0;
    if (this.props.location === 'All') {
      data.map((l) => {

        if (this.props.dept === 'All') {
          l.Dept.map((d) => {

            d.tests.map((t) => {
              professionals += (t.young_male_count * this.BoolToInt(this.props.young_male) +
                t.young_female_count * this.BoolToInt(this.props.young_female) +
                t.old_male_count * this.BoolToInt(this.props.old_male) +
                t.old_female_count * this.BoolToInt(this.props.old_female));
              no_tests += t.no_tests;
              divisor += 1;
            });

          });
        }
        else {
          var idx = dept_to_idx[this.props.dept];

          // l.dept.
          l.Dept[idx].tests.map((t) => {
            professionals += (t.young_male_count * this.BoolToInt(this.props.young_male) +
              t.young_female_count * this.BoolToInt(this.props.young_female) +
              t.old_male_count * this.BoolToInt(this.props.old_male) +
              t.old_female_count * this.BoolToInt(this.props.old_female));
            no_tests += t.no_tests;
            divisor += 1;
          });
        }

      });
    }
    else {

      if (this.props.dept === 'All') {
        var idx = loc_to_idx[this.props.location];
        data[idx].Dept.map((d) => {

          d.tests.map((t) => {
            professionals += (t.young_male_count * this.BoolToInt(this.props.young_male) +
              t.young_female_count * this.BoolToInt(this.props.young_female) +
              t.old_male_count * this.BoolToInt(this.props.old_male) +
              t.old_female_count * this.BoolToInt(this.props.old_female));
            no_tests += t.no_tests;
            divisor += 1;
          });
        });

      }
      else {
        //select only one dept
        var idx_l = loc_to_idx[this.props.location];
        var idx_D = dept_to_idx[this.props.dept];

        data[idx_l].Dept[idx_D].tests.map((t) => {
          professionals += (t.young_male_count * this.BoolToInt(this.props.young_male) +
            t.young_female_count * this.BoolToInt(this.props.young_female) +
            t.old_male_count * this.BoolToInt(this.props.old_male) +
            t.old_female_count * this.BoolToInt(this.props.old_female));
          no_tests += t.no_tests;
          divisor += 1;
        });
      }
    }
    no_tests = Math.floor(no_tests / divisor);




    // central data cards
    // -> testno and percent

    var test_number_arr = Array.from({ length: total_bio_markers }).fill(0);
    var percent_arr = Array.from({ length: total_bio_markers }).fill(0);
    var color_arr = Array.from({ length: total_bio_markers });
    var temp_arr = Array.from({ length: total_bio_markers });
    var comment = Array.from({ length: total_bio_markers });
    var sub_tests_arr = Array.from({ length: total_bio_markers });

    // console.log("firstly:", percent_arr)

    let all_male_abnormal_percentages = [21, 12, 5, 18, 10, 20, 7, 28, 53, 0, 3]

    for (var i = 0; i < total_bio_markers; i++) {
      // console.log("this point", total_bio_markers)
      temp_arr[i] = i;
    }
    divisor = 0;

    if (this.props.location === 'All') {
      data.map((l) => {

        if (this.props.dept === 'All') {
          l.Dept.map((d) => {

            d.central_data.map((t) => {
              console.log("at T: \n", t)
              // all_male_abnormal_percentages.push(t.all_male_abnormal)
              var idx_c = t.profile_id - 1;
              test_number_arr[idx_c] += (t.young_male_test_no * this.BoolToInt(this.props.young_male) +
                t.young_female_test_no * this.BoolToInt(this.props.young_female) +
                t.old_male_test_no * this.BoolToInt(this.props.old_male) +
                t.old_female_test_no * this.BoolToInt(this.props.old_female));

              percent_arr[idx_c] +=
                (t.young_male_abnormal * this.BoolToInt(this.props.young_male) +
                  t.young_female_abnormal * this.BoolToInt(this.props.young_female) +
                  t.old_male_abnormal * this.BoolToInt(this.props.old_male) +
                  t.old_female_abnormal * this.BoolToInt(this.props.old_female)
                );

                console.log("all the data\n",
                t.young_male_abnormal, this.BoolToInt(this.props.young_male),
                t.young_female_abnormal, this.BoolToInt(this.props.young_female),
                t.old_male_abnormal, this.BoolToInt(this.props.old_male),
                t.old_female_abnormal, this.BoolToInt(this.props.old_female),
                "=>",
                percent_arr[idx_c]
              )
              comment[idx_c] = t.comment;
              sub_tests_arr[idx_c] = t.sub_tests;
            });
            divisor += 1;
          });
        }
        else {
          var idx = dept_to_idx[this.props.dept];

          // l.dept.
          l.Dept[idx].central_data.map((t) => {
            var idx_c = t.profile_id - 1;
            test_number_arr[idx_c] += (t.young_male_test_no * this.BoolToInt(this.props.young_male) +
              t.young_female_test_no * this.BoolToInt(this.props.young_female) +
              t.old_male_test_no * this.BoolToInt(this.props.old_male) +
              t.old_female_test_no * this.BoolToInt(this.props.old_female));

            percent_arr[idx_c] +=
              (t.young_male_abnormal * this.BoolToInt(this.props.young_male) +
                t.young_female_abnormal * this.BoolToInt(this.props.young_female) +
                t.old_male_abnormal * this.BoolToInt(this.props.old_male) +
                t.old_female_abnormal * this.BoolToInt(this.props.old_female)
              );
            comment[idx_c] = t.comment;
            sub_tests_arr[idx_c] = t.sub_tests;
          });
          divisor += 1;
        }

      });
    }
    else {

      if (this.props.dept === 'All') {
        var idx = loc_to_idx[this.props.location];
        data[idx].Dept.map((d) => {

          d.central_data.map((t) => {
            var idx_c = t.profile_id - 1;
            test_number_arr[idx_c] += (t.young_male_test_no * this.BoolToInt(this.props.young_male) +
              t.young_female_test_no * this.BoolToInt(this.props.young_female) +
              t.old_male_test_no * this.BoolToInt(this.props.old_male) +
              t.old_female_test_no * this.BoolToInt(this.props.old_female));
            percent_arr[idx_c] +=
              (t.young_male_abnormal * this.BoolToInt(this.props.young_male) +
                t.young_female_abnormal * this.BoolToInt(this.props.young_female) +
                t.old_male_abnormal * this.BoolToInt(this.props.old_male) +
                t.old_female_abnormal * this.BoolToInt(this.props.old_female)
              );
            comment[idx_c] = t.comment;
            sub_tests_arr[idx_c] = t.sub_tests;
          });
          divisor += 1;
        });

      }
      else {
        //select only one dept
        var idx_l = loc_to_idx[this.props.location];
        var idx_D = dept_to_idx[this.props.dept];

        data[idx_l].Dept[idx_D].central_data.map((t) => {
          var idx_c = t.profile_id - 1;
          test_number_arr[idx_c] += (t.young_male_test_no * this.BoolToInt(this.props.young_male) +
            t.young_female_test_no * this.BoolToInt(this.props.young_female) +
            t.old_male_test_no * this.BoolToInt(this.props.old_male) +
            t.old_female_test_no * this.BoolToInt(this.props.old_female));
          percent_arr[idx_c] +=
            (t.young_male_abnormal * this.BoolToInt(this.props.young_male) +
              t.young_female_abnormal * this.BoolToInt(this.props.young_female) +
              t.old_male_abnormal * this.BoolToInt(this.props.old_male) +
              t.old_female_abnormal * this.BoolToInt(this.props.old_female)
            );
          comment[idx_c] = t.comment;
          sub_tests_arr[idx_c] = t.sub_tests;
        });
        divisor += 1;
      }
    }

    for (var i = 0; i < total_bio_markers; i++) {
      //percent_arr[i] = Math.floor(percent_arr[i] / (divisor * denominator));  // round value
      percent_arr[i] = percent_arr[i] / (divisor * denominator);
      // color_arr[i] = "rgb(" +  247* percent_arr[i]/100 + ", 77, 77)"; 
      if (percent_arr[i] <= green_max[i]) color_arr[i] = "#f68080";
      // else if (percent_arr[i] > green_max[i] && percent_arr[i] <= orange_max[i]) color_arr[i] = "#f53737";
      else if (percent_arr[i] > green_max[i] && percent_arr[i] <= orange_max[i]) color_arr[i] = "#fa9898";
      else if (percent_arr[i] > orange_max[i]) color_arr[i] = "#d40000"
    }


    var table_data = Array.from({ length: total_bio_markers }).fill(0);

    for (var i = 0; i < total_bio_markers; i++) {
      table_data[i] = {
        idx: i,
        profile_name: convert_index_test[i],
        test_number: test_number_arr[i],
        percent: percent_arr[i],
        color: color_arr[i],
        comment: comment[i],
        sub_tests: sub_tests_arr[i],
      };
    }

    // console.log("table_data", table_data)

    if (pUp === true) {
      table_data.sort(function (a, b) {
        if (a.profile_name < b.profile_name) return -1;
        return 1;
      });
    }
    else if (pDown === true) {
      table_data.sort(function (a, b) {
        if (a.profile_name > b.profile_name) return -1;
        return 1;
      });
    }
    else if (tUp === true) {
      table_data.sort(function (a, b) {
        if (a.test_number > b.test_number) return -1;
        return 1;
      });
    }
    else if (tDown === true) {
      table_data.sort(function (a, b) {
        if (a.test_number < b.test_number) return -1;
        return 1;
      });
    }
    else if (rUp === true) {
      table_data.sort(function (a, b) {
        if (a.percent > b.percent) return -1;
        return 1;
      });
    }
    else if (rDown === true) {
      table_data.sort(function (a, b) {
        if (a.percent < b.percent) return -1;
        return 1;
      });
    }

    console.log("in table data:", table_data)


    const centralData = professionals > 0 ? (
      table_data.map((node, idx) => {
        
        let holdData = table_data
        holdData.map((each, theId) => each.percent = all_male_abnormal_percentages[theId])
        console.log("check here", holdData)
        localStorage.setItem("concern_area_data", JSON.stringify(holdData))

        if (node.test_number > 0) {
          card_display += 1;
          return (
            <div className="row pb-1 custom_font test-row-margin" key={node.idx}>
              <div className="col-lg-12 col-11 d-flex">

                <div style={{ width: "100%" }} className="card shadow">
                  <div className="card-body hover_change" onClick={this.cardClicked} id={node.profile_name}>
                    <div className="container" id={node.profile_name}>
                      <div className="row" id={node.profile_name}>
                        {/* <div className="col-lg-1 text-center hidden-xs">
                            {data.profile_id}
                          </div> */}
                        <div className="col-lg-5 col-9 text-left" id={node.profile_name}>
                          <span id="profile_span" id={node.profile_name}>
                            {node.profile_name === "Complete Blood Count" || node.profile_name === "Blood Counts" && (
                              <img src={bloodCount} className="right-side-name" id={node.profile_name} />
                            )}
                            {node.profile_name === "Kidney Profile" && (
                              <img src={kidney} alt="" className="right-side-name" id={node.profile_name} />
                            )}
                            {node.profile_name === "Lipid Profile" && (
                              <img src={lipids} className="right-side-name" id={node.profile_name} />
                            )}
                            {node.profile_name === "Thyroid" && (
                              <img src={thyroid} alt="" className="right-side-name" id={node.profile_name} />
                            )}
                            {node.profile_name === "Liver Profile" && <img src={liver} className="right-side-name" id={node.profile_name} />}

                            {node.profile_name === "Diabetes Monitoring" && (
                              <img src={diabetes} alt="" className="right-side-name" id={node.profile_name} />
                            )}
                            {node.profile_name === "Vitamin D" && (
                              <img src={vitaminD} className="right-side-name" id={node.profile_name} />
                            )}
                            {node.profile_name === "Vitamin B12" && (
                              <img src={vitaminB12} alt="" className="right-side-name" id={node.profile_name} />
                            )}
                            {node.profile_name === "Electrolyte Profile" && (
                              <img src={electrolytes} className="right-side-name" id={node.profile_name} />
                            )}
                            {node.profile_name === "Anemia" && (
                              <img src={anemia} alt="" className="right-side-name" id={node.profile_name} />
                            )}
                            {node.profile_name === "Urinalysis" &&
                              <img src={urine} className="right-side-name" id={node.profile_name} />
                            }
                            {node.profile_name === "Anemia Studies" &&
                              <img src={anemia_image} className="right-side-name" id={node.profile_name} />
                            }
                            {node.profile_name === "Blood Clotting" &&
                              <img src={blood_clotting} className="right-side-name" id={node.profile_name} />
                            }
                            {node.profile_name === "Vitamin Profile" &&
                              <img src={vitamin_image} className="right-side-name" id={node.profile_name} />
                            }
                            {node.profile_name === "Cancer Profile" &&
                              <img src={cancer_image} className="right-side-name" id={node.profile_name} />
                            }
                            {node.profile_name === "Thyroid Profile" &&
                              <img src={thyroid_image} className="right-side-name" id={node.profile_name} />
                            }
                            {node.profile_name === "BMI & BP" &&
                              <img src={bmi_image} className="right-side-name" id={node.profile_name} />
                            }
                            {node.profile_name === "Body Screening" &&
                              <img src={body_screening} className="right-side-name" id={node.profile_name} />
                            }
                            {node.profile_name === "Covid" &&
                              <img src={covid_image} className="right-side-name" id={node.profile_name} />
                            }
                            {node.profile_name === "Lifestyle" &&
                              <img src={lifestyle_image} className="right-side-name" id={node.profile_name} />
                            }
                            {node.profile_name === "Body System" &&
                              <img src={body_system} className="right-side-name" id={node.profile_name} />
                            }
                          </span>{" "}
                          <span
                            // id = "title"
                            id={node.profile_name}
                            className=" left-margin"
                          >
                            {node.profile_name}
                          </span>
                        </div>
                        <div className="col-lg-2 col-3 text-center" id={node.profile_name}>
                          {node.test_number}
                        </div>
                        <div className="col-lg-4 col-12 right-side" id={node.profile_name}>
                          <ProgressBar
                            id="title"
                            bgColor={node.color}
                            labelColor="black"
                            labelAlignment="outside"
                            completed={all_male_abnormal_percentages[idx]}
                          />
                          {/* <div style={{ position: "absolute", right: "0", bottom: "2px", fontWeight: "600", margin: "0px -3px 0px 0px" }}>
                            {"%"}
                          </div> */}
                        </div>
                        <div className="col-lg-12 pt-2 text-secondary text-center" id={node.profile_name}>
                          <small id={node.profile_name}>{node.comment}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <a
                    href="https://niroggyan.s3.ap-south-1.amazonaws.com/corporate-dashboard-excel-files/blood_counts.xlsx"
                    download
                  >
                  </a> */}
                  {/* {node.idx} */}

                  {/* <i title={node.percent !== 0 ? "download abnormal tests data" : "no abnormal tests"}
                    className={`fas fa-file-download ${node.percent !== 0 ? "download-file-css" : "download-file-disable-css"}`}>
                  </i> */}

                  <DownloadComponent the_id={node.idx} percentValue={node.percent} />

                </div>
              </div>
            </div>
          );

        }
      })
    ) : (
      <div className="center">No data here</div>
    );



    return (
      <div className="container">
        <div className="row no-gutters text-center test-bottom-margin">
          <div className="col-lg-12">
            <br />
            <br />
            <h2>
              Company Health Profile{" "}
              <AiFillInfoCircle data-tip="The health data of your company is displayed below" className="mb-1 insight-i-icon" />
            </h2>
            <div>
              <p className="text-center fontchange mb-4">
                # Professionals who got tested: <b>{professionals}</b>{" "} | # Tests conducted for every individual: <b>{no_tests}</b>
              </p>
              <br />
              {/* <p className="text-center fontchange">
                No. of tests conducted for every individual: <b>{no_tests}</b>
              </p> */}
            </div>
            <div className="row text-uppercase bolded">

              {/* <div className="col-lg-2 hidden-xs remove-left-margin">
                      <span data-tip="Serial No. of the test"><FiChevronUp id="sr_no" onClick = {this.buttonSortUp}/> Sr. <FiChevronDown id="sr_no" onClick={this.buttonSortDown} /></span>
                    </div> */}
              <div className="col-lg-4 col-4 text-left pl-5">
                <span className="margin_left" data-tip="Test profile Name"> Profile
                  <span id="profile_up" onClick={this.buttonSortUp} className={"upDownButton " + (pUp ? "black" : "grey")} >▲</span>
                  <span id="profile_down" onClick={this.buttonSortDown} className={"upDownButton " + (pDown ? "black" : "grey")}>▼</span>
                </span>
              </div>
              <div className="col-lg-4 col-4">
                <span className="margin_left" data-tip="Total No. of test conducted"> Tests
                  <span id="tests_up" onClick={this.buttonSortUp} className={"upDownButton " + (tUp ? "black" : "grey")} >▲</span>
                  <span id="tests_down" onClick={this.buttonSortDown} className={"upDownButton " + (tDown ? "black" : "grey")}>▼</span>
                </span>
              </div>
              <div className="col-lg-4 col-4">
                <span data-tip="The employees with test results out of range"> Out Of Range
                  <span id="percentage_up" onClick={this.buttonSortUp} className={"upDownButton " + (rUp ? "black" : "grey")} >▲</span>
                  <span id="percentage_down" onClick={this.buttonSortDown} className={"upDownButton " + (rDown ? "black" : "grey")}>▼</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        {centralData}


        {this.state.openModal && (
          <Modal
            isOpen={this.state.openModal}
            onRequestClose={this.closeModal}
            style={
              {
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)'
                },
                content: {
                  height: '0px',
                  width: '0px',
                  top: '50%',
                  left: '50%',
                }
              }
            }
          >
            <div className=" row text-center human_body pop_up" id="unblurred" >
              <div className="col-lg-4"></div>
              <div className="col-lg-4">
                <div className="card border bg-eee border-0">
                  {this.state.nodeInnerText === "Complete Blood Count"}
                  {this.state.nodeInnerText === "Kidney"}
                  {this.state.nodeInnerText === "Lipids"}
                  {this.state.nodeInnerText === "Thyroid"}
                  {this.state.nodeInnerText === "Liver"}
                  {this.state.nodeInnerText === "Diabetes"}
                  {this.state.nodeInnerText === "Vitamin D"}
                  {this.state.nodeInnerText === "Vitamin B12"}
                  {this.state.nodeInnerText === "Electrolytes & Minerals"}
                  {this.state.nodeInnerText === "Anemia"}
                  {this.state.nodeInnerText === "Urine"}
                  {this.state.nodeInnerText === "Body Screening"}
                  {this.state.nodeInnerText === "BP & BMI"}
                  {this.state.nodeInnerText === "Lifestyle"}
                  {this.state.nodeInnerText === "Covid"}
                  {this.state.nodeInnerText === "Body System"}

                </div>
              </div>
              <div className="col-lg-4"></div>
              <div className="col-lg-12">
                <div>
                  <h5>{this.state.nodeInnerText}</h5>

                  <p className="card-text">
                    {comment[convert_test_index[this.state.nodeInnerText]]}<br></br>Test includes:-
                    <ul className="card-text-fix scroll">
                      {
                        sub_tests_arr[convert_test_index[this.state.nodeInnerText]].map((item) => {
                          return <li>{item}</li>
                        })
                      }
                    </ul>
                  </p>

                  <button
                    type="button"
                    className="btn btn-primary mb-2"
                    onClick={this.closeModal}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}

        <ReactTooltip />
      </div>
    );
  }
}



export default Tests;
