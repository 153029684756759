import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "../routes/footer";
import "../css/style.css";


import CBC from "../images/profile/CBC.svg";
import Anemia from "../images/profile/Anemia.svg";
import Diabetes from "../images/profile/Diabetes.svg";
import Electrolytes from "../images/profile/Electrolytes.svg";
import Kidneys from "../images/profile/Kidneys.svg";
import Lipid from "../images/profile/Lipid1.png";
import Liver from "../images/profile/Liver.svg";
import Thyroid from "../images/profile/Thyroid.svg";
import Urine from "../images/profile/Urine.svg";
import VitaminB12 from "../images/profile/VitaminB12.svg";
import VitaminD from "../images/profile/VitaminD.svg";
import laggards from "../images/profile/laggards.png"
import learners from "../images/profile/learners.png"
import leaders from "../images/profile/leaders.png"
import { JSON_LINK } from "../utils";

import axios from "axios";
import ReactTooltip from 'react-tooltip';
import { AiFillInfoCircle, AiOutlineFileExcel } from "react-icons/ai";
import { CSVLink } from "react-csv";
import { COLORS } from "../color";
import { useHistory } from "react-router-dom";

class Profile extends Component {
  state = {
    data: [],
  };

  componentDidMount() {

    // credentials check

    const loginData = localStorage.getItem("credentials")
    if (loginData) {
      //      
    } else {
      window.location = "/login"
    }

    var config = {
      headers: { 'Access-Control-Allow-Origin': '*' }
    };
    axios
      .get(
        JSON_LINK
      )
      .then((res) => {
        this.setState({
          data: res.data,
        });
        console.log("profiles data:--------------------------\n", res.data)
      });
    // console.log("Mount");
  }



  render() {
    // console.log("RENDER");
    var headers = [
      { label: "Leaders Data", key: "LeadersData" },
      { label: "Learners Data", key: "LearnersData" },
      { label: "Laggards Data", key: "LaggardsData" }
    ];

    var csv_data = [];
    var good_csv_data = [];
    var warning_csv_data = [];
    var bad_csv_data = [];


    var good_old_male = 0;
    var good_young_male = 0;
    var good_old_female = 0;
    var good_young_female = 0;
    var warning_old_male = 0;
    var warning_young_male = 0;
    var warning_old_female = 0;
    var warning_young_female = 0;
    var bad_old_male = 0;
    var bad_young_male = 0;
    var bad_old_female = 0;
    var bad_young_female = 0;
    var total_old_male = 0;
    var total_young_male = 0;
    var total_old_female = 0;
    var total_young_female = 0;

    const { data } = this.state;

    // console.log(data);

    data.map((l) => {
      l.Dept.map((d) => {
        d.profile_data.map((p) => {
          if (p.status === "good") {
            good_csv_data.push(p.emp_id)

            if (p.group == "old_male") good_old_male += 1;
            else if (p.group == "old_female") good_old_female += 1;
            else if (p.group == "young_male") good_young_male += 1;
            else if (p.group == "young_female") good_young_female += 1;
          }
          else if (p.status === 'warning') {
            warning_csv_data.push(p.emp_id)
            if (p.group == "old_male") warning_old_male += 1;
            else if (p.group == "old_female") warning_old_female += 1;
            else if (p.group == "young_male") warning_young_male += 1;
            else if (p.group == "young_female") warning_young_female += 1;
          }
          else if (p.status === 'bad') {
            bad_csv_data.push(p.emp_id);
            if (p.group == "old_male") bad_old_male += 1;
            else if (p.group == "old_female") bad_old_female += 1;
            else if (p.group == "young_male") bad_young_male += 1;
            else if (p.group == "young_female") bad_young_female += 1;
          }
        });
      });
    });

    total_old_male = good_old_male + warning_old_male + bad_old_male;
    total_young_male = good_young_male + warning_young_male + bad_young_male;
    total_old_female = good_old_female + warning_old_female + bad_old_female;
    total_young_female = good_young_female + warning_young_female + bad_young_female;



    for (var i = 0; i < 10000; i++) {
      csv_data.push({
        LeadersData: good_csv_data[i], LearnersData: warning_csv_data[i], LaggardsData: bad_csv_data[i]
        // gooddata: good_csv_data[i], warningdata: warning_csv_data[i], baddata: bad_csv_data[i]
      })
    }


    return (
      <div style={{ backgroundColor: COLORS.background_color }}>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
            <Navbar page="profile" />
          </div>
        </div>

        <div className="row headings">
          <div className="col-lg-12 text-center ">
            <h1 className="bold-heading">Your people are at different stages of wellness </h1>
            <h3>Know which group is faring worse than the rest, and take actions accordingly</h3>
          </div>


        </div>

        <div className="d-flex flex-wrap justify-content-around mb-3 text-center align-self-start">
          <div className="p-2 m-2 bg-success-imp rounded fix_height  shadow">
            <h3 className="text-center text-white pb-1 " id="profile"><img className="img-icon" src={leaders} />LEADERS <AiFillInfoCircle data-tip="These are the employees who have healthy test parameters" className="mb-1 leaders-i-icon" /></h3>
            <div className="card shadow">
              <div className="card-body">
                <table id="table1" className="table table-bordered ">
                  {/* <the
                    <tr>
                      <th className="tableHead">This is good section</th>
                    </tr>
                  </thead> */}
                  <tbody >
                    <tr>
                      <td className="text-center text-font-heading">{"Those who fare well in Total Chol HDL Ratio, Uric Acid & TSH"}</td>
                    </tr>
                    <tr>
                      <td className="left-aline text-font"><span style={{ display: "flex", justifyContent: "center" }}>All Professionals : {Math.round((good_old_male + good_young_male + good_old_female + good_young_female) / (total_old_male + total_young_male + total_old_female + total_young_female) * 100)}%</span></td>
                    </tr>
                    <tr>
                      <td className="left-aline text-font"><span style={{ display: "flex", justifyContent: "center" }}>Males &#60; 35 yrs : {good_young_male === 0 ? 0 : Math.round((good_young_male) / (total_young_male) * 100)}%</span></td>
                    </tr>
                    <tr>
                      <td className="left-aline text-font"><span style={{ display: "flex", justifyContent: "center" }}>Males &#62;= 35 yrs : {Math.round((good_old_male) / (total_old_male) * 100)}%</span></td>
                    </tr>
                    <tr>
                      <td className="left-aline text-font excel-css">
                        <a href="https://niroggyan.s3.ap-south-1.amazonaws.com/other_health_dashboards_files/gujrat-data-unihealth-files/leaders_learners_laggards_excels/leaders.xlsx"
                          style={{ textDecoration: "none", color: "dogerblue" }}
                          target="_blank"
                          title="Get all leaders name"
                        >
                          <span style={{ display: "flex", justifyContent: "center" }}>
                            Get Data <i className="far fa-file-excel" style={{ margin: "3px 0px 0px 3px" }}></i>
                          </span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="p-2 m-2 bg-warning-imp rounded fix_height shadow">
            <h3 className="text-center text-white pb-1"><img className="img-icon" src={learners} />LEARNERS <AiFillInfoCircle data-tip="These are the employees whose parameters are on the borderline" className="mb-1 learners-i-icon" /></h3>
            <div className="card shadow">
              <div className="card-body">
                <table id="table2" className="table table-bordered">
                  {/* <thead>
                    <tr>
                      <th className="tableHead">This is warning section</th>
                    </tr>
                  </thead> */}
                  <tbody>
                    <tr>
                      <td className="text-center text-font-heading">{"Those who have slight problems  in Total Chol HDL Ratio, Uric Acid & TSH"}</td>
                    </tr>
                    <tr>
                      <td className="left-aline text-font"><span style={{ display: "flex", justifyContent: "center" }}>All Professionals : {Math.round((warning_old_male + warning_young_male + warning_old_female + warning_young_female) / (total_old_male + total_young_male + total_old_female + total_young_female) * 100)}%</span></td>
                    </tr>


                    <tr>
                      <td className="left-aline text-font"><span style={{ display: "flex", justifyContent: "center" }}>Males &#60; 35 yrs : {warning_young_male === 0 ? 0 : Math.round((warning_young_male) / (total_young_male) * 100)}%</span></td>
                    </tr>

                    <tr>
                      <td className="left-aline text-font"><span style={{ display: "flex", justifyContent: "center" }}>Males &#62;= 35 yrs : {Math.round((warning_old_male) / (total_old_male) * 100)}%</span></td>
                    </tr>

                    <tr>
                      <td className="left-aline text-font excel-css">
                        <a href="https://niroggyan.s3.ap-south-1.amazonaws.com/other_health_dashboards_files/gujrat-data-unihealth-files/leaders_learners_laggards_excels/learners.xlsx"
                          style={{ textDecoration: "none", color: "dogerblue" }}
                          target="_blank"
                          title="Get all learners name"
                        >
                          <span style={{ display: "flex", justifyContent: "center" }}>
                            Get Data <i className="far fa-file-excel" style={{ margin: "3px 0px 0px 3px" }}></i>
                          </span>
                        </a>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="p-2 m-2 bg-danger-imp rounded fix_height shadow">
            <h3 className="text-center text-white pb-1"> <img className="img-icon" src={laggards} />LAGGARDS <AiFillInfoCircle data-tip="These are the employees who need to improve their test parameters" className="mb-1 laggards-i-icon" /></h3>
            <div className="card shadow">
              <div className="card-body">
                <table id="table3" className="table table-bordered">
                  {/* <thead>
                    <tr>
                      <th className="tableHead">This is bad section</th>
                    </tr>
                  </thead> */}
                  <tbody>
                    <tr>
                      <td className="text-center text-font-heading">{"Those who have major problems in Total Chol HDL Ratio, Uric Acid & TSH"}</td>
                    </tr>
                    <tr>
                      <td className="left-aline text-font"><span style={{ display: "flex", justifyContent: "center" }}>All Professionals : {Math.round((bad_old_male + bad_young_male + bad_old_female + bad_young_female) / (total_old_male + total_young_male + total_old_female + total_young_female) * 100)}%</span></td>
                    </tr>


                    <tr>
                      <td className="left-aline text-font"><span style={{ display: "flex", justifyContent: "center" }}>Males &#60; 35 yrs : {bad_young_male === 0 ? 0 : Math.round((bad_young_male) / (total_young_male) * 100)}%</span></td>
                    </tr>

                    <tr>
                      <td className="left-aline text-font"><span style={{ display: "flex", justifyContent: "center" }}>Males &#62;= 35 yrs : {Math.round((bad_old_male) / (total_old_male) * 100)}%</span></td>
                    </tr>

                    <tr>
                      <td className="left-aline text-font excel-css">
                        <a href="#"
                          style={{ textDecoration: "none", color: "grey" }}
                          // target="_blank"
                          title="no data"
                        >
                          <span style={{ display: "flex", justifyContent: "center" }}>
                            Get Data <i className="far fa-file-excel" style={{ margin: "3px 0px 0px 3px" }}></i>
                          </span>
                        </a>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="container top-margin">
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4"></div>
            <div className="col-lg-4 text-right">
              {/* <CSVLink data={csv_data} className="btn btn-primary mb-4" headers={headers} filename={"employee_data.csv"}>
                Download CSV <span><AiOutlineFileExcel size="2em" className="mb-1" /></span>
              </CSVLink> */}
            </div>
          </div>
        </div>




        <div className="row top-margin">
          <Footer />
        </div>
        <ReactTooltip />
      </div>
    );
  }
}

export default Profile;
